<template>
    <footer class="footer">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-6">
                    {{ appName() }}
                </div>
                <div class="col-sm-6">
                    <div class="text-sm-right d-none d-sm-block">
                        &copy; {{ currentYear() }} <a href="https://resourcebase.com/" target="_blank">Resourcebase</a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        mounted () {
            //
        },
        methods: {
            currentYear: function () {
                return new Date().getFullYear();
            },
            appName: function () {
                return this.$root.$data.app.name ? this.$root.$data.app.name : '';
            }
        }
    }
</script>