import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

import App from './components/App';
import Home from './components/Home';
import ClientAdd from './components/clients/ClientAdd';
import ClientApiCalls from './components/clients/ClientApiCalls';
import ClientInitialUsers from './components/clients/ClientInitialUsers';
import Clients from './components/clients/ClientsList';
import ClientSettings from './components/clients/ClientSettings';
import Login from './components/Login';
import ResetPassword from './components/ResetPassword';
import Users from './components/users/UsersList';
import UserAdd from './components/users/UserAdd';

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/login',
            name: 'login',
            component: Login
        },
        {
            path: '/reset-password/:code',
            name: 'reset-password',
            component: ResetPassword
        },
        {
            path: '/clients',
            name: 'clients',
            component: Clients
        },
        {
            path: '/clients/initial-client-users',
            name: 'client-initial-users',
            component: ClientInitialUsers
        },
        {
            path: '/clients/add',
            name: 'client-add',
            component: ClientAdd
        },
        {
            path: '/clients/api-calls/:client_id',
            name: 'client-api-calls',
            component: ClientApiCalls
        },
        {
            path: '/clients/settings/:client_id',
            name: 'client-settings',
            component: ClientSettings
        },
        {
            path: '/users',
            name: 'users',
            component: Users
        },
        {
            path: '/users/add',
            name: 'user-add',
            component: UserAdd
        }
    ]
});

/**
 * Import the rest of components
 */
require('./vue.components');

/**
 * Create app storage
 */
var storage = {
    app: null,
    user: null,
    token: null,
    requested_route: null,
    current_route: null,
    public_pages: ['login', 'reset-password']
};

/**
 * Common functions
 */
function isLoggedIn () {
    const loggedOut = ((storage.user == null) || (storage.token == null));

    return !loggedOut;
}

router.beforeEach((to, from, next) => {
    if ((storage.public_pages.indexOf(to.name) === -1)
        && !isLoggedIn()
        && (document.cookie.indexOf('resourcebase_admin_api_token') === -1)
    ) {
        storage.requested_route = to.name;
        next({ name: 'login' });
    } else {
        next();
    }
    storage.current_route = to;
});

/**
 * Start the app
 */
const app = new Vue({
    el: '#app',
    data: storage,
    components: { App },
    router,
    methods: {
        isLoggedIn: function () {
            const loggedOut = ((this.$root.$data.user == null) || (this.$root.$data.token == null));

            return !loggedOut;
        },
        setCookie: function (name, value, expiry = null) {
            let options = { samesite: 'Lax' };
            if (expiry) {
                options.expires = expiry;
            }
            this.$cookie.set(name, value, options);
        },
        doLogout: function () {
            this.$cookie.delete('resourcebase_admin_api_token');
            this.$root.$data.user = null;
            this.$root.$data.token = null;
            if (this.$route.name != 'login') {
                this.$router.push({ name: 'login' });
            }
        },
        findComponent: function (parentComponent, componentName) {
            var found = false;
            parentComponent.$children.forEach((component, index) => {
                if (component.component_name == componentName) {
                    found = component;
                }
                if (component.$vnode) {
                    if (component.$vnode.tag.indexOf(componentName) > -1) {
                        found = component;
                    }
                }
            });

            return found;
        },
        isPublicPage: function (pageName) {
            return (this.$root.$data.public_pages.indexOf(pageName) > -1);
        },
        ucfirst: function (text) {
            if ((typeof text === 'string') && (text.length > 0)) {
                return (text[0].toUpperCase() + text.substring(1));
            }

            return '';
        },
        expandSideNav: function () {
            setTimeout(function () {
                $('.mm-active').removeClass('mm-active');
                var links = document.getElementsByClassName("side-nav-link-ref");
                var matchingMenuItem = null;
                for (var i = 0; i < links.length; i++) {
                    if (window.location.pathname === links[i].pathname) {
                        matchingMenuItem = links[i];
                        break;
                    }
                }

                if (matchingMenuItem) {
                    matchingMenuItem.classList.add("active");
                    var parent = matchingMenuItem.parentElement;

                    if (parent) {
                        parent.classList.add("mm-active");
                        const parent2 = parent.parentElement.closest("ul");
                        if (parent2 && (parent2.id !== "side-menu")) {
                            parent2.classList.add("mm-show");

                            const parent3 = parent2.parentElement;
                            if (parent3) {
                                parent3.classList.add("mm-active");
                                var childAnchor = parent3.querySelector(".has-arrow");
                                var childDropdown = parent3.querySelector(".has-dropdown");
                                if (childAnchor) {
                                    childAnchor.classList.add("mm-active");
                                }
                                if (childDropdown) {
                                    childDropdown.classList.add("mm-active");
                                }

                                const parent4 = parent3.parentElement;
                                if (parent4) {
                                    parent4.classList.add("mm-show");
                                }
                                const parent5 = parent4.parentElement;
                                if (parent5) {
                                    parent5.classList.add("mm-active");
                                }
                            }
                        }
                    }
                }
            }, 500);
        }
    }
});