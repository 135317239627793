<template>
    <div class="vertical-menu">
        <simplebar v-if="!isCondensed" :settings="settings" class="h-100">
            <side-nav-content></side-nav-content>
        </simplebar>

        <simplebar v-else class="h-100">
            <side-nav-content></side-nav-content>
        </simplebar>
    </div>
</template>

<script>
    import simplebar from "simplebar-vue";

    export default {
        components: { simplebar },
        props: {
            isCondensed: {
                type: Boolean,
                default: false
            }
        },
        data () {
            return {
                settings: {
                    minScrollbarLength: 60
                }
            }
        },
        mounted () {
            //
        }
    }
</script>
