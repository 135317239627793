<template>
    <ol class="breadcrumb m-0">
        <li v-for="item in items" :class="itemClass(item)" :key="item.id">
            <span v-if="item.link">
                <router-link :to="{ name: item.link }">{{ item.text }}</router-link>
            </span>
            <span v-else>{{ item.text }}</span>
        </li>
    </ol>
</template>

<script>
    export default {
        props: ['items'],
        mounted () {
            this.$root.expandSideNav();
        },
        methods: {
            itemClass: function (item) {
                return item.active ? 'breadcrumb-item active' : 'breadcrumb-item';
            }
        }
    }
</script>
