<template>
    <div>
        <div class="page-content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="page-title-box d-flex align-items-center justify-content-between">
                            <h4 class="mb-0 font-size-18">User list</h4>

                            <div class="page-title-right">
                                <breadcrumbs :items="breadcrumbs"></breadcrumbs>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                <div v-if="loading"><loading></loading></div>

                <div v-if="(!errors && !loading)">
                    <div v-if="num_items == 0" class="alert alert-warning" role="alert">
                        No results
                    </div>

                    <div v-if="num_items > 0" class="row">
                        <div v-for="item in items" :key="item.id" class="col-xl-3 col-sm-6">
                            <div class="card text-center">
                                <div class="card-body">
                                    <div class="avatar-sm mx-auto mb-4">
                                        <span
                                            class="avatar-title rounded-circle bg-soft-primary text-primary font-size-16"
                                        >{{ initial(item.name) }}</span>
                                    </div>
                                    <h5 class="font-size-15">
                                        <a
                                            href="#"
                                            class="text-dark"
                                            @click.prevent="showUserForm(item.id)"
                                        >{{ item.name }}</a>
                                    </h5>
                                    <p class="text-muted">{{ item.email }}</p>
                                </div>
                                <div class="card-footer bg-transparent border-top">
                                    <div class="contact-links d-flex font-size-18">
                                        <div class="flex-fill">
                                            <a
                                                href="#"
                                                v-b-tooltip.hover
                                                title="Edit"
                                                @click.prevent="showUserForm(item.id)"
                                            >
                                                <i class="far fa-edit"></i>
                                            </a>
                                        </div>
                                        <div v-if="!isCurrentUser(item.id)" class="flex-fill">
                                            <a
                                                href="#"
                                                v-b-tooltip.hover
                                                title="Delete"
                                                @click.prevent="confirmDeleteUser(item)"
                                                class="text-danger"
                                            >
                                                <i class="far fa-trash"></i>
                                            </a>
                                        </div>
                                        <div v-else class="flex-fill text-disabled">
                                            <i class="far fa-trash"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <site-footer></site-footer>

        <div
            class="modal fade"
            id="user-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="user-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog">
                <user-form></user-form>
            </div>
        </div>

        <div
            class="modal fade"
            id="delete-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="delete-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog">
                <user-delete></user-delete>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['add'],
        data () {
            return {
                breadcrumbs: [
                    {
                        id: 0,
                        text: "Admin users",
                        active: true,
                        link: 'users'
                    },
                    {
                        id: 1,
                        text: "User list"
                    }
                ],
                loading: false,
                errors: false,
                edit_blocked: false,
                items: [],
                num_items: 0
            }
        },
        mounted () {
            this.getUsers();
            if (this.add) {
                this.showUserForm();
            }

            this.$root.expandSideNav();
        },
        methods: {
            showUserForm: function (id = 0) {
                if (!this.edit_blocked) {
                    var component = this.$root.findComponent(this, 'user-form');
                    if (component) {
                        component.editUser(id);
                        $('#user-modal').modal('show');
                    }
                }
            },
            getUsers: function () {
                this.loading = true;
                this.errors = false;

                /**
                 * Send request to API
                 */
                const url = this.$root.$data.app.api_url + '/users';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    vm.items = response.data.users;
                    vm.num_items = vm.items.length;
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() =>
                    vm.loading = false
                );
            },
            isCurrentUser: function (id) {
                return (this.$root.$data.user.id == id);
            },
            confirmDeleteUser: function (item) {
                this.edit_blocked = true;
                var component = this.$root.findComponent(this, 'user-delete');
                if (component) {
                    component.confirmDeletion(item);
                    $('#delete-modal').modal('show');
                }
            },
            initial: function (name) {
                const letter = name.substr(0,1);

                return letter.toUpperCase();
            }
        }
    }
</script>
