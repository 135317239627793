<template>
    <div>
        <div class="account-pages my-5 pt-sm-5">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-8 col-lg-6 col-xl-5">
                        <div class="card overflow-hidden">
                            <div class="bg-soft-primary">
                                <div class="text-primary p-4">
                                    <h5 class="text-primary">{{ $root.$data.app.name }}</h5>
                                    <p>Reset password</p>
                                </div>
                            </div>
                            <div class="card-body pt-0">
                                <login-header></login-header>

                                <div class="p-2">
                                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                                    <div v-if="loading"><loading></loading></div>

                                    <div v-else>
                                        <div v-if="updated">
                                            <div class="alert alert-success" role="alert">
                                                <i class="far fa-check mr-2"></i>
                                                <span v-html="updated"></span>
                                            </div>
                                        </div>

                                        <div v-if="(!updated && email)">
                                            <form v-on:submit.prevent="onSubmit()">
                                                <p>Please enter a new password for {{ email }}</p>
                                                <div class="form-group">
                                                    <label for="new_password" id="new_password-label" class="mandatory-label">
                                                        New password
                                                    </label>
                                                    <input
                                                        v-model="new_password"
                                                        type="password"
                                                        id="new_password"
                                                        class="form-control mandatory-field"
                                                        aria-describedby="new_password-help"
                                                        :readonly="updating"
                                                        autocomplete="off"
                                                    >
                                                    <small id="new_password-help" class="form-text text-muted">
                                                        Minimum 6 characters
                                                    </small>
                                                </div>
                                                <div v-if="new_password" class="form-group">
                                                    <label for="confirm_new_password" id="confirm_new_password-label" class="mandatory-label">
                                                        Confirm new password
                                                    </label>
                                                    <input
                                                        v-model="confirm_new_password"
                                                        type="password"
                                                        id="confirm_new_password"
                                                        class="form-control mandatory-field"
                                                        :readonly="updating"
                                                        autocomplete="off"
                                                    >
                                                </div>
                                                <div v-if="(new_password && confirm_new_password)">
                                                    <span v-if="!updating">
                                                        <span>
                                                            <button type="submit" class="btn btn-primary">
                                                                <i class="far fa-save mr2"></i> Save
                                                            </button>
                                                        </span>
                                                    </span>
                                                    <span v-else>
                                                        <button type="button" class="btn btn-primary">
                                                            <i class="fal fa-spinner-third fa-spin mr2"></i>
                                                            <span style="opacity: 0.5">Save</span>
                                                        </button>
                                                    </span>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    <div v-if="(updated || (!email && errors))" class="text-center">
                                        <router-link :to="{ name: 'login' }">
                                            <i class="fas fa-sign-in-alt mr2"></i> Click here to login
                                            <span v-if="errors">or request a new password reset link</span>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <login-footer></login-footer>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                loading: false,
                errors: false,
                updating: false,
                updated: false,
                user_id: 0,
                email: '',
                new_password: '',
                confirm_new_password: ''
            }
        },
        mounted () {
            this.validateCode();
        },
        methods: {
            validateCode: function () {
                this.loading = true;
                this.errors = false;
                this.updating = false;
                this.updated = false;
                this.user_id = 0;
                this.email = '';

                /**
                 * Send request to API
                 */
                var vm = this;
                const url = this.$root.$data.app.api_url + '/user/validate-password-reset-code';

                axios({
                    method: 'post',
                    url: url,
                    data: {
                        code: this.$route.params.code
                    }
                })
                .then(response => {
                    vm.user_id = response.data.user.id;
                    vm.email = response.data.user.email;
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() =>
                    vm.showForm()
                );
            },
            showForm: function () {
                this.loading = false;
                var vm = this;
                setTimeout(function () {
                    vm.new_password = '';
                    vm.confirm_new_password = '';
                }, 200);
            },
            onSubmit: function () {
                this.updating = true;
                this.errors = false;

                /**
                 * Send reset password request to API
                 */
                const data = {
                    code: this.$route.params.code,
                    user_id: this.user_id,
                    new_password: this.new_password,
                    confirm_new_password: this.confirm_new_password
                };

                const url = this.$root.$data.app.api_url + '/user/reset-password';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(response => {
                    vm.updated = 'Success';
                    if (response.data.message) {
                        vm.updated = response.data.message;
                    }
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response) {
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error;
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = '';
                                $('.mandatory-label').removeClass('text-danger');
                                $('.mandatory-field').removeClass('field-error');
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    vm.errors += value.join('<br>') + '<br>';
                                    $('#' + key + '-label').addClass('text-danger');
                                    $('#' + key).addClass('field-error');
                                }
                            }
                        }
                    }
                })
                .finally(() =>
                    vm.updating = false
                );
            }
        }
    }
</script>
