<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="forgotten-password-modal-label">Send password reset link</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true"><i class="fal fa-times"></i></span>
            </button>
        </div>

        <div v-if="sent">
            <div class="modal-body">
                <div class="alert alert-success" role="alert">
                    <i class="far fa-check mr-2"></i>
                    <span v-html="sent"></span>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" data-dismiss="modal">
                    <i class="fas fa-times mr2"></i> Close
                </button>
            </div>
        </div>

        <div v-else>
            <form v-on:submit.prevent="onSubmit()">
                <div class="modal-body">
                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                    <p>
                        Enter your email address below and click Send. You will then receive a password reset link.
                    </p>

                    <div class="form-group">
                        <input
                            v-model="email"
                            type="email"
                            id="email_address"
                            class="form-control mandatory-field"
                            :readonly="sending"
                            placeholder="Email address"
                        >
                    </div>
                </div>
                <div class="modal-footer">
                    <span v-if="!sending">
                        <span>
                            <button type="submit" class="btn btn-primary" :disabled="!email">
                                <i class="fas fa-paper-plane mr2"></i> Send
                            </button>
                        </span>
                    </span>
                    <span v-else>
                        <button type="button" class="btn btn-primary">
                            <i class="fal fa-spinner-third fa-spin mr2"></i>
                            <span style="opacity: 0.5">Send</span>
                        </button>
                    </span>
                    <button type="button" class="btn btn-light" data-dismiss="modal" :disabled="sending">
                        <i class="fas fa-times mr2"></i> Cancel
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                errors: false,
                sending: false,
                sent: false,
                email: ''
            }
        },
        mounted () {
            //
        },
        methods: {
            resetForm: function () {
                this.errors = false;
                this.sending = false;
                this.sent = false;
                this.email = this.$parent.$data.email;
            },
            onSubmit: function () {
                this.sending = true;
                this.errors = false;

                /**
                 * Send request to API
                 */
                var data = {
                    email: this.email,
                    app_info: this.$root.$data.app
                };

                const url = this.$root.$data.app.api_url + '/user/send-password-reset-link';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(response => {
                    vm.sent = 'Success';
                    if (response.data.message) {
                        vm.sent = response.data.message;
                    }
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response) {
                        if (error.response.data.error) {
                            vm.errors = error.response.data.error;
                        }
                    }
                })
                .finally(() =>
                    vm.sending = false
                );
            }
        }
    }
</script>
