<template>
    <clients-list :add="true"></clients-list>
</template>

<script>
    export default {
        mounted () {
            //
        }
    }
</script>
