<template>
    <div>
        <site-footer></site-footer>
    </div>
</template>

<script>
    export default {
        mounted () {
            this.$root.expandSideNav();
        }
    }
</script>
