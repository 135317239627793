<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <div v-if="loading"><loading></loading></div>

        <div v-else>
            <form v-on:submit.prevent="updateRole()">
                <div class="form-group">
                    <label
                        :for="nameId()"
                        :id="nameLabelId()"
                        class="mandatory-label"
                        style="font-weight: bold"
                    >
                        Role name
                        <span v-if="duplicate">(duplicate of {{ duplicate.name }})</span>
                    </label>
                    <div v-if="editName" class="input-group">
                        <input
                            v-model="name"
                            type="text"
                            :id="nameId()"
                            class="form-control mandatory-field"
                            maxlength="20"
                            :aria-describedby="nameUpdateId()"
                            :disabled="updatingName"
                        >
                        <div class="input-group-append">
                            <button
                                v-if="!updatingName"
                                :key="'submit-name'"
                                class="btn btn-secondary"
                                type="submit"
                                :id="nameUpdateId()"
                                :title="nameUpdateTitle()"
                                v-html="nameUpdateIcon()"
                            ></button>
                            <button
                                v-else
                                :key="'updating-name'"
                                class="btn btn-secondary btn-disabled"
                                type="button"
                                title="Updating"
                            ><i class="fal fa-spinner-third fa-spin"></i></button>
                        </div>
                    </div>
                    <div v-else>
                        <a
                            href="#"
                            class="primary float-right"
                            title="Edit"
                            @click.prevent="toggleEditName(true)"
                            style="margin-left: 15px; font-size: 18px"
                        ><i class="fas fa-edit"></i></a>
                        {{ name }}
                    </div>
                </div>
                <div v-if="(id > 0)">
                    <div class="form-group">
                        <label
                            :for="descriptionId()"
                            :id="descriptionLabelId()"
                            class="mandatory-label"
                            style="font-weight: bold"
                        >Description</label>
                        <div v-if="editDescription" class="input-group">
                            <input
                                v-model="description"
                                type="text"
                                :id="descriptionId()"
                                class="form-control mandatory-field"
                                :aria-describedby="descriptionUpdateId()"
                                :disabled="updatingDescription"
                            >
                            <div class="input-group-append">
                                <button
                                    v-if="!updatingDescription"
                                    :key="'submit-description'"
                                    class="btn btn-secondary"
                                    type="submit"
                                    :id="descriptionUpdateId()"
                                    title="Save"
                                ><i class="far fa-save font-size-16"></i></button>
                                <button
                                    v-else
                                    :key="'updating-description'"
                                    class="btn btn-secondary btn-disabled"
                                    type="button"
                                    title="Updating"
                                ><i class="fal fa-spinner-third fa-spin"></i></button>
                            </div>
                        </div>
                        <div v-else>
                            <a
                                href="#"
                                class="primary float-right"
                                title="Edit"
                                @click.prevent="toggleEditDescription(true)"
                                style="margin-left: 15px; font-size: 18px"
                            ><i class="fas fa-edit"></i></a>
                            {{ description || '-' }}
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="custom-control custom-radio">
                            <input
                                v-model="auto_approve_role"
                                :value="id"
                                type="radio"
                                class="custom-control-input"
                                :id="'auto-approve-role' + id"
                            >
                            <label class="custom-control-label" :for="'auto-approve-role' + id">
                                Default user role for auto approve
                            </label>
                        </div>
                    </div>
                    <hr>
                    <div style="max-height: 300px; overflow-y: auto; padding-right: 10px">
                        <div v-for="group in all_permissions" :key="group.group_key" class="mb-1">
                            <div
                                class="float-right mr-2"
                                style="margin-top: 7px; margin-left: 15px; height: 25px; overflow: hidden"
                            >
                                <span v-if="updating_group_permissions">
                                    <loading align="right" size="1"></loading>
                                </span>
                                <span v-else>
                                    <span v-if="!groupPermissionGranted(group.group_key)">
                                        <span @click.prevent="updateGroupPermission(group.group_key, true)">
                                            <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                        </span>
                                    </span>
                                    <span v-else>
                                        <span>
                                            <a
                                                href="#"
                                                class="primary"
                                                @click.prevent="updateGroupPermission(group.group_key, false)"
                                            >
                                                <i class="far fa-toggle-on"></i>
                                            </a>
                                        </span>
                                    </span>
                                </span>
                            </div>
                            <p class="mb-0 p-2" style="background-color: #f8f9fa">
                                <strong>{{ group.group_name }}</strong>
                            </p>
                            <div
                                v-if="groupPermissionGranted(group.group_key)"
                                class="p-2 mb-2"
                                style="border-left: 1px solid #f8f9fa; border-right: 1px solid #f8f9fa;
                                    border-bottom: 1px solid #f8f9fa"
                            >
                                <div v-for="permission in group.permissions" :key="permission.key">
                                    <div>
                                        <div
                                            class="float-right"
                                            style="margin-left: 15px; height: 25px; overflow: hidden"
                                        >
                                            <span v-if="updating_group_permissions">
                                                <loading align="right" size="1"></loading>
                                            </span>
                                            <span v-else>
                                                <span v-if="!permissionGranted(permission.key)">
                                                    <span @click.prevent="updatePermission(permission.key, true)">
                                                        <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                    </span>
                                                </span>
                                                <span v-else>
                                                    <span>
                                                        <a
                                                            href="#"
                                                            class="primary"
                                                            @click.prevent="updatePermission(permission.key, false)"
                                                        >
                                                            <i class="far fa-toggle-on"></i>
                                                        </a>
                                                    </span>
                                                </span>
                                            </span>
                                        </div>
                                        <div style="min-height: 25px">{{ permission.name }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <span>
                        <button
                            :key="'duplicate-role-button'"
                            type="button"
                            class="btn btn-secondary mr-2"
                            :disabled="deleting"
                            @click.prevent="$parent.duplicateRole(name, description, role_permissions)"
                        >
                            <i class="fas fa-copy mr2"></i> Duplicate role
                        </button>
                    </span>
                    <span v-if="!deleting">
                        <button
                            :key="'delete-role-button'"
                            type="button"
                            class="btn btn-danger"
                            @click.prevent="deleteRole()"
                        >
                            <i class="fas fa-trash mr2"></i> Delete role
                        </button>
                    </span>
                    <span v-else>
                        <button
                            :key="'deleting-role-button'"
                            class="btn btn-danger btn-disabled"
                            type="button"
                        >
                            <i class="fal fa-spinner-third fa-spin mr2"></i> Delete role
                        </button>
                    </span>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['role', 'permissions', 'auto_approve_role_original', 'duplicate'],
        data () {
            return {
                loading: true,
                errors: false,
                id: 0,
                name: '',
                description: '',
                role_permissions: [],
                all_permissions: [],
                editName: false,
                updatingName: false,
                editDescription: false,
                updatingDescription: false,
                deleting: false,
                original_id: 0,
                auto_approve_role: null,
                disable_auto_approval_role_update: false,
                updating_group_permissions: 0,
                duplicate_role: null
            }
        },
        mounted () {
            this.loading = false;
            if (this.role) {
                this.id = this.role.id;
                this.name = this.role.name;
                this.description = this.role.description || '';
                this.role_permissions = this.role.permissions;
                this.all_permissions = this.permissions;
                this.original_id = this.role.original_id || 0;
                this.disable_auto_approval_role_update = true;
                this.auto_approve_role = this.auto_approve_role_original;
            } else {
                this.editName = true;
            }
        },
        watch: {
            auto_approve_role_original: function (val) {
                this.disable_auto_approval_role_update = true;
                this.auto_approve_role = val;
            },
            auto_approve_role: function (val) {
                if (this.disable_auto_approval_role_update) {
                    this.disable_auto_approval_role_update = false;

                    return null;
                }
                this.$parent.$parent.setAutoApproveRole(this.original_id ? this.original_id : val);
            }
        },
        methods: {
            nameLabelId: function () {
                return 'name-' + this.id + '-label';
            },
            nameId: function () {
                return 'name-' + this.id;
            },
            nameUpdateId: function () {
                return 'name-update-' + this.id;
            },
            nameUpdateTitle: function () {
                return (this.id > 0) ? 'Save' : 'Add';
            },
            nameUpdateIcon: function () {
                return (this.id > 0) ? '<i class="far fa-save font-size-14"></i>' : '<i class="fas fa-plus"></i>';
            },
            descriptionLabelId: function () {
                return 'description-' + this.id + '-label';
            },
            descriptionId: function () {
                return 'description-' + this.id;
            },
            descriptionUpdateId: function () {
                return 'description-update-' + this.id;
            },
            toggleEditName: function (on) {
                this.editName = on;
            },
            toggleEditDescription: function (on) {
                this.editDescription = on;
            },
            updateRole: function () {
                if (this.id == 0) {
                    this.createRole();

                    return false;
                }
                this.errors = false;
                this.updatingName = true;
                this.updatingDescription = true;

                /**
                 * Send request to API
                 */
                const data = {
                    name: this.name,
                    description: this.description
                };
                const url = this.$root.$data.app.api_url + '/clients/role/' + this.id;
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    vm.$parent.updateRoleName(vm.id, vm.name);
                    vm.toggleEditName(false);
                    vm.toggleEditDescription(false);
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#client-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        if (typeof error.response.data.error === 'string') {
                            vm.errors = error.response.data.error;
                        } else if (typeof error.response.data.error === 'object') {
                            vm.errors = '';
                            $('.mandatory-label').removeClass('text-danger');
                            $('.mandatory-field').removeClass('field-error');
                            for (const [key, value] of Object.entries(error.response.data.error)) {
                                vm.errors += value.join('<br>') + '<br>';
                                $('#' + key + '-' + vm.id + '-label').addClass('text-danger');
                                $('#' + key + '-' + vm.id).addClass('field-error');
                            }
                        }
                    }
                })
                .finally(() => {
                    vm.updatingName = false;
                    vm.updatingDescription = false;
                });
            },
            createRole: function () {
                this.errors = false;
                this.updatingName = true;

                /**
                 * Send request to API
                 */
                let data = {
                    name: this.name
                };
                if (this.duplicate) {
                    if (this.duplicate.description) {
                        data.description = this.duplicate.description;
                    }
                }
                const url = this.$root.$data.app.api_url + '/clients/role';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    if (vm.duplicate) {
                        vm.duplicate_role = response.data.role;
                        vm.addDuplicatePermissions();

                        return null;
                    }
                    vm.name = '';
                    vm.$parent.addRole(response.data.role);
                    vm.updatingName = false;
                })
                .catch(function (error) {
                    vm.updatingName = false;
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#client-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        if (typeof error.response.data.error === 'string') {
                            vm.errors = error.response.data.error;
                        } else if (typeof error.response.data.error === 'object') {
                            vm.errors = '';
                            $('.mandatory-label').removeClass('text-danger');
                            $('.mandatory-field').removeClass('field-error');
                            for (const [key, value] of Object.entries(error.response.data.error)) {
                                vm.errors += value.join('<br>') + '<br>';
                                $('#' + key + '-' + vm.id + '-label').addClass('text-danger');
                                $('#' + key + '-' + vm.id).addClass('field-error');
                            }
                        }
                    }
                });
            },
            permissionGranted: function (key) {
                var found = false;
                this.role_permissions.forEach(rolePermission => {
                    if (rolePermission.permission == key) {
                        found = rolePermission.granted;
                    }
                });

                return found;
            },
            togglePermission: function (key, granted) {
                var found = -1;
                this.role_permissions.forEach((rolePermission, index) => {
                    if (rolePermission.permission == key) {
                        found = index;
                    }
                });
                if (found > -1) {
                    this.role_permissions[found].granted = granted;
                } else {
                    const newPermission = {
                        permission: key,
                        granted: granted
                    }
                    this.role_permissions.push(newPermission);
                }
            },
            addDuplicatePermissions: function () {
                const numPermissions = this.duplicate_role.permissions.length;
                if (numPermissions == this.duplicate.permissions.length) {
                    this.name = '';
                    this.$parent.addRole(this.duplicate_role);
                    this.updatingName = false;
                    this.duplicate_role = null;

                    return null;
                }
                const permission = this.duplicate.permissions[numPermissions];
                this.duplicate_role.permissions.push(permission);
                if (!permission.granted) {
                    this.addDuplicatePermissions();

                    return null;
                }
                this.updatePermission(permission.permission, 1);
            },
            updatePermission: function (key, granted) {
                if (!this.duplicate) {
                    if ((key == 'assets_approval_required') && granted) {
                        this.updatePermission('assets_logging_required', true);
                    }
                    if ((key == 'assets_logging_required') && !granted) {
                        this.updatePermission('assets_approval_required', false);
                    }
                    this.togglePermission(key, granted);
                }
                var reverseKey = key;
                var reverseValue = !granted;

                /**
                 * Send request to API.
                 */
                const data = {
                    granted: granted,
                };
                const url = this.$root.$data.app.api_url + '/clients/role/'
                    + (this.duplicate ? this.duplicate_role.id : this.id) + '/permission/' + key;
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    if (vm.duplicate) {
                        vm.addDuplicatePermissions();

                        return null;
                    }
                    if (vm.updating_group_permissions) {
                        vm.updating_group_permissions--;
                    }
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#client-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (vm.duplicate) {
                        vm.addDuplicatePermissions();

                        return null;
                    }
                    vm.togglePermission(reverseKey, reverseValue);
                });
            },
            groupPermissionGranted: function (groupKey) {
                let granted = false;
                this.all_permissions.forEach((group) => {
                    if (!granted && (group.group_key == groupKey)) {
                        group.permissions.forEach((permission) => {
                            const permissionGranted = this.permissionGranted(permission.key);
                            if (permissionGranted) {
                                granted = true;
                            }
                        });
                    }
                });

                return granted;
            },
            updateGroupPermission: function (groupKey, granted) {
                let permissionsToUpdate = [];
                this.all_permissions.forEach((group) => {
                    if (group.group_key == groupKey) {
                        group.permissions.forEach((permission) => {
                            const permissionGranted = this.permissionGranted(permission.key);
                            if ((permissionGranted && !granted) || (!permissionGranted && granted)) {
                                permissionsToUpdate.push(permission.key);
                            }
                        });
                    }
                });
                this.updating_group_permissions = permissionsToUpdate.length;
                permissionsToUpdate.forEach((key, index) => {
                    var vm = this;
                    setTimeout(function () {
                        vm.updatePermission(key, granted);
                    }, index * 250);
                });
            },
            deleteRole: function () {
                this.errors = false;
                this.deleting = true;

                /**
                 * Send request to API
                 */
                const url = this.$root.$data.app.api_url + '/clients/role/' + this.id;
                var vm = this;

                axios({
                    method: 'delete',
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    // Delete role tab and switch to next available role.
                    vm.$parent.removeRole(vm.id);
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#client-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.deleting = false;
                });
            }
        }
    }
</script>
