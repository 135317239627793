<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="change-password-modal-label">Change password</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true"><i class="fal fa-times"></i></span>
            </button>
        </div>

        <div v-if="updated">
            <div class="modal-body">
                <div class="alert alert-success" role="alert">
                    <i class="far fa-check mr-2"></i>
                    <span v-html="updated"></span>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" data-dismiss="modal">
                    <i class="fas fa-times mr2"></i> Close
                </button>
            </div>
        </div>

        <div v-else>
            <form v-on:submit.prevent="onSubmit()">
                <div class="modal-body">
                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                    <div class="form-group">
                        <label for="current_password" id="current_password-label" class="mandatory-label">
                            Enter your current password
                        </label>
                        <input
                            v-model="current_password"
                            type="password"
                            id="current_password"
                            class="form-control mandatory-field"
                            :readonly="updating"
                        >
                    </div>
                    <div v-if="current_password" class="form-group">
                        <label for="new_password" id="new_password-label" class="mandatory-label">
                            New password
                        </label>
                        <input
                            v-model="new_password"
                            type="password"
                            id="new_password"
                            class="form-control mandatory-field"
                            aria-describedby="new_password-help"
                            :readonly="updating"
                        >
                        <small id="new_password-help" class="form-text text-muted">
                            Minimum 6 characters
                        </small>
                    </div>
                    <div v-if="(current_password && new_password)" class="form-group">
                        <label for="confirm_new_password" id="confirm_new_password-label" class="mandatory-label">
                            Confirm new password
                        </label>
                        <input
                            v-model="confirm_new_password"
                            type="password"
                            id="confirm_new_password"
                            class="form-control mandatory-field"
                            :readonly="updating"
                        >
                    </div>
                </div>
                <div class="modal-footer">
                    <div v-if="(current_password && new_password && confirm_new_password)">
                        <span v-if="!updating">
                            <span>
                                <button type="submit" class="btn btn-primary">
                                    <i class="far fa-save mr2"></i> Save
                                </button>
                            </span>
                        </span>
                        <span v-else>
                            <button type="button" class="btn btn-primary">
                                <i class="fal fa-spinner-third fa-spin mr2"></i>
                                <span style="opacity: 0.5">Save</span>
                            </button>
                        </span>
                    </div>
                    <button type="button" class="btn btn-light" data-dismiss="modal" :disabled="updating">
                        <i class="fas fa-times mr2"></i> Cancel
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                errors: false,
                updating: false,
                updated: false,
                current_password: '',
                new_password: '',
                confirm_new_password: ''
            }
        },
        mounted () {
            //
        },
        methods: {
            resetForm: function () {
                this.errors = false;
                this.updating = false;
                this.updated = false;
                this.current_password = '';
                this.new_password = '';
                this.confirm_new_password = '';
            },
            onSubmit: function () {
                this.updating = true;
                this.errors = false;

                /**
                 * Send request to API
                 */
                const data = {
                    current_password: this.current_password,
                    new_password: this.new_password,
                    confirm_new_password: this.confirm_new_password
                };

                const url = this.$root.$data.app.api_url + '/user/change-password';
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    vm.updated = 'Success';
                    if (response.data.message) {
                        vm.updated = response.data.message;
                    }

                    setTimeout(function () {
                        $('#change-password-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response) {
                        if (error.response.status == 401) {
                            // missing/lost API token - close modal and logout user
                            $('#user-modal').modal('hide');
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error;
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = '';
                                $('.mandatory-label').removeClass('text-danger');
                                $('.mandatory-field').removeClass('field-error');
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    vm.errors += value.join('<br>') + '<br>';
                                    $('#' + key + '-label').addClass('text-danger');
                                    $('#' + key).addClass('field-error');
                                }
                            }
                        }
                    }
                })
                .finally(() =>
                    vm.updating = false
                );
            }
        }
    }
</script>
