<template>
    <div class="card text-center">
        <div v-if="((id == 0) && !edit)" class="card-body">
            <div class="avatar-sm mx-auto mt-5 mb-5">
                <span
                    class="avatar-title rounded-circle bg-soft-primary text-primary font-size-16"
                >
                    <div class="contact-links d-flex font-size-18">
                        <div class="flex-fill">
                            <a
                                href="#"
                                v-b-tooltip.hover
                                title="Add user"
                                @click.prevent="showForm()"
                            >
                                <i class="far fa-plus"></i>
                            </a>
                        </div>
                    </div>
                </span>
            </div>
        </div>

        <div v-if="((id > 0) && !edit)">
            <div class="card-body">
                <div class="avatar-sm mx-auto mb-4">
                    <span
                        class="avatar-title rounded-circle bg-soft-primary text-primary font-size-16"
                    >{{ initial(first_name) }}</span>
                </div>
                <h5 class="font-size-15">
                    <a href="#" class="text-dark" @click.prevent="showForm()">{{ first_name }} {{ last_name }}</a>
                </h5>
                <p class="text-muted">{{ email }}</p>
                <p class="text-muted">
                    <span v-if="show_password">
                        {{ password }}
                        <a
                            href="#"
                            class="badge badge-primary font-size-11 m-1"
                            v-b-tooltip.hover
                            title="Hide password"
                            @click.prevent="togglePassword()"
                        >
                            <i class="far fa-eye-slash" style="font-size: 16px"></i>
                        </a>
                    </span>

                    <span v-else>
                        <span v-html="maskedPassword()"></span>
                        <span>
                            <a
                                href="#"
                                class="badge badge-primary font-size-11 m-1"
                                v-b-tooltip.hover
                                title="Show password"
                                @click.prevent="togglePassword()"
                            >
                                <i class="far fa-eye" style="font-size: 16px"></i>
                            </a>
                        </span>
                    </span>
                </p>
            </div>
            <div class="card-footer bg-transparent border-top">
                <div class="contact-links d-flex font-size-18">
                    <div class="flex-fill">
                        <a
                            href="#"
                            v-b-tooltip.hover
                            title="Edit"
                            @click.prevent="showForm()"
                        >
                            <i class="far fa-edit"></i>
                        </a>
                    </div>
                    <div class="flex-fill">
                        <a
                            href="#"
                            v-b-tooltip.hover
                            title="Delete"
                            @click.prevent="$parent.confirmDelete(original_data)"
                            class="text-danger"
                        >
                            <i class="far fa-trash"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="edit">
            <form v-on:submit.prevent="onSubmit()">
                <button type="submit" style="display: none"></button>
                <div class="card-body">
                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                    <div class="row my-2">
                        <label
                            :for="'first_name-' + id"
                            :id="'name-label-' + id"
                            class="col-xl-3 col-sm-4 col-form-label mandatory-label"
                        >Name</label>
                        <div class="col-xl-9 col-sm-8 input-group">
                            <input
                                v-model="first_name"
                                type="text"
                                :id="'first_name-' + id "
                                class="form-control mandatory-field"
                                :readonly="updating"
                                placeholder="First"
                            >
                            <input
                                v-model="last_name"
                                type="text"
                                :id="'last_name-' + id"
                                class="form-control mandatory-field"
                                :readonly="updating"
                                placeholder="Last"
                            >
                        </div>
                    </div>

                    <div class="row my-2">
                        <label
                            :for="'email-' + id"
                            :id="'email-label-' + id"
                            class="col-xl-3 col-sm-4 col-form-label mandatory-label"
                        >Email</label>
                        <div class="col-xl-9 col-sm-8" style="overflow-x: hidden">
                            <input
                                v-model="email"
                                type="email"
                                :id="'email-' + id"
                                class="form-control mandatory-field"
                                :readonly="updating"
                            >
                        </div>
                    </div>

                    <div class="row my-2">
                        <label
                            :for="'password-' + id"
                            :id="'password-label-' + id"
                            class="col-xl-3 col-sm-4 col-form-label mandatory-label"
                        >Password</label>
                        <div class="col-xl-9 col-sm-8">
                            <input
                                v-model="password"
                                type="password"
                                :id="'password-' + id"
                                class="form-control mandatory-field"
                                :readonly="updating"
                            >
                        </div>
                    </div>
                </div>

                <div class="card-footer bg-transparent border-top">
                    <div class="contact-links d-flex font-size-18">
                        <div v-if="!updating" class="flex-fill">
                            <a
                                href="#"
                                v-b-tooltip.hover
                                :title="button_text"
                                @click.prevent="onSubmit()"
                            >
                                <i :class="button_icon"></i>
                            </a>
                        </div>
                        <div v-else class="flex-fill">
                            <span>
                                <i class="fal fa-spinner-third fa-spin"></i>
                            </span>
                        </div>

                        <div class="flex-fill">
                            <span><span>
                                <a
                                    href="#"
                                    v-b-tooltip.hover
                                    title="Cancel"
                                    @click.prevent="cancelEdit()"
                                >
                                    <i class="far fa-times"></i>
                                </a>
                            </span></span>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['data'],
        data () {
            return {
                original_data: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    password: '',
                },
                edit: false,
                id: 0,
                first_name: '',
                last_name: '',
                email: '',
                password: '',
                button_icon: 'far fa-check',
                button_text: 'Add',
                updating: false,
                errors: false,
                show_password: false
            }
        },
        mounted () {
            this.updateData();
        },
        watch: {
            data: function (val) {
                this.updateData();
            },
            errors: function (val) {
                if (!val) {
                    $('.mandatory-label').removeClass('text-danger');
                    $('.mandatory-field').removeClass('field-error');
                }
            }
        },
        methods: {
            updateData: function () {
                if (this.data) {
                    this.original_data = this.data;
                    this.edit = false;
                    this.id = this.data.id;
                    this.first_name = this.data.first_name;
                    this.last_name = this.data.last_name;
                    this.email = this.data.email;
                    this.password = this.data.password;
                    this.button_icon = 'far fa-save';
                    this.button_text = 'Save';
                    this.updating = false;
                    this.errors = false;
                    this.show_password = false;
                }
            },
            showForm: function () {
                this.edit = true;

                if (this.id == 0) {
                    var vm = this;
                    vm.first_name = '';
                    vm.last_name = '';
                    setTimeout(function () {
                        vm.email = '';
                        vm.password = '';
                    }, 500);
                }
            },
            maskedPassword: function () {
                const mask = '&bull;';
                return mask.repeat(this.password.length);
            },
            togglePassword: function () {
                this.show_password = !this.show_password;
            },
            cancelEdit: function () {
                this.errors = false;
                this.first_name = this.original_data.first_name;
                this.last_name = this.original_data.last_name;
                this.email = this.original_data.email;
                this.password = this.original_data.password;
                this.edit = false;
            },
            initial: function (name) {
                const letter = name.substr(0,1);

                return letter.toUpperCase();
            },
            onSubmit: function () {
                this.updating = true;
                this.errors = false;

                /**
                 * Send request to API
                 */
                var path = '/clients/initial-user/' + this.id;
                var data = {
                    first_name: this.first_name,
                    last_name: this.last_name,
                    email: this.email,
                    password: this.password
                };
                var method = 'put';

                if (this.id == 0) {
                    path = '/clients/initial-user';
                    method = 'post';
                }

                const url = this.$root.$data.app.api_url + path;
                var vm = this;

                axios({
                    method: method,
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    vm.$parent.getUsers();
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response) {
                        if (error.response.status == 401) {
                            // missing/lost API token - logout user
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error;
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = '';
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    vm.errors += value.join('<br>') + '<br>';
                                    if ((key == 'first_name') || (key == 'last_name')) {
                                        $('#name-label-' + vm.id).addClass('text-danger');
                                    } else {
                                        $('#' + key + '-label-' + vm.id).addClass('text-danger');
                                    }
                                    $('#' + key + '-' + vm.id).addClass('field-error');
                                }
                            }
                        }
                    }
                })
                .finally(() => {
                    vm.updating = false;

                    if (!vm.errors) {
                        vm.edit = false;
                    }
                });
            }
        }
    }
</script>
