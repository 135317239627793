<template>
    <div class="mt-5 text-center">
        <div>
            <p>&copy; {{ currentYear() }} <a href="https://resourcebase.com/" target="_blank">Resourcebase</a></p>
        </div>
    </div>
</template>

<script>
    export default {
        mounted () {
            //
        },
        methods: {
            currentYear: function () {
                return new Date().getFullYear();
            }
        }
    }
</script>