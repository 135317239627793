<template>
    <users-list :add="true"></users-list>
</template>

<script>
    export default {
        mounted () {
            //
        }
    }
</script>
