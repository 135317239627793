<template>
    <div>
        <router-link :to="{ name: 'home' }">
            <div class="avatar-md profile-user-wid mb-4">
                <span class="avatar-title rounded-circle bg-light">
                    <img
                        src="/images/rb-favicon.png"
                        alt="Resourcebase"
                        class="rounded-circle"
                        height="34"
                    >
                </span>
            </div>
        </router-link>
    </div>
</template>

<script>
    export default {
        mounted () {
            //
        }
    }
</script>