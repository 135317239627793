<template>
    <div class="row">
        <div class="col-md-11">
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label
                            :for="'colour_name' + id"
                            :id="'colour_name-label' + id"
                            class="mandatory-label"
                        >Name</label>
                        <input
                            v-model="colour_name"
                            type="text"
                            :id="'colour_name' + id"
                            class="form-control mandatory-field"
                            placeholder="Give this colour a name"
                            v-on:blur="updateColour()"
                        >
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label
                            :for="'colour_picker' + id"
                            :id="'colour-label' + id"
                            class="mandatory-label"
                        >Background colour</label>
                        <colorpicker
                            :color="colour"
                            v-model="colour"
                            :id="'colour_picker' + id"
                            class="mandatory-field"
                            v-on:blur="updateColour()"
                        ></colorpicker>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label
                            :for="'text_colour_picker' + id"
                            :id="'text_colour-label' + id"
                            class="mandatory-label"
                        >Text colour</label>
                        <colorpicker
                            :color="text_colour"
                            v-model="text_colour"
                            :id="'text_colour_picker' + id"
                            class="mandatory-field"
                            v-on:blur="updateColour()"
                        ></colorpicker>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-1">
            <div class="form-group">
                <label>&nbsp;</label>
                <button
                    type="button"
                    class="btn btn btn-danger btn-block"
                    v-b-tooltip.hover
                    title="Delete colour"
                    @click.prevent="$parent.deleteColour(id)"
                    style="padding-top: 9px; padding-bottom: 8px"
                >
                    <i class="fas fa-trash"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['data'],
        data () {
            return {
                id: null,
                colour_name: null,
                colour: null,
                text_colour: null,
                edited: false
            }
        },
        mounted () {
            this.id = this.data.id;
            this.colour_name = this.data.colour_name;
            this.colour = this.data.colour;
            this.text_colour = this.data.text_colour;
        },
        watch: {
            colour_name: function (val, oldVal) {
                if (oldVal === null) {
                    return null;
                }
                this.onChange();
            },
            colour: function (val, oldVal) {
                if (oldVal === null) {
                    return null;
                }
                this.onChange();
                //this.updateColour();
            },
            text_colour: function (val, oldVal) {
                if (oldVal === null) {
                    return null;
                }
                this.onChange();
                //this.updateColour();
            }
        },
        methods: {
            onChange: function () {
                this.edited = true;
                this.$parent.editing_colours = true;
            },
            updateColour: function () {
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
                $('.mandatory-field input').removeClass('field-error');
                this.$parent.errors = false;
                /**
                 * Send request to API
                 */
                const data = {
                    colour_name: this.colour_name,
                    colour: this.colour,
                    text_colour: this.text_colour
                };
                console.log('updting colour', data);
                const url = this.$root.$data.app.api_url + '/clients/additional-colour/' + this.id;
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    vm.edited = false;
                    vm.$parent.editing_colours = false;
                })
                .catch(function (error) {
                    vm.$parent.errors = 'Something went wrong, please try again';
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#client-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        if (typeof error.response.data.error === 'string') {
                            vm.$parent.errors = error.response.data.error;
                        } else if (typeof error.response.data.error === 'object') {
                            vm.$parent.errors = '';
                            let errorHtml = null;
                            for (const [key, value] of Object.entries(error.response.data.error)) {
                                errorHtml = value.join('<br>') + '<br>';
                                vm.$parent.errors += errorHtml;
                                $('#' + key + '-label' + vm.id).addClass('text-danger');
                                $('#' + key + vm.id).addClass('field-error');
                                $('#' + key + '_picker' + vm.id + ' input').addClass('field-error');
                            }
                        }
                    }
                });
            }
        }
    }
</script>
