<template>
    <div class="navbar-header">
        <div class="d-flex">
            <div class="navbar-brand-box">
                <router-link class="logo logo-light" :to="{ name: 'home' }">
                    <span class="logo-sm">
                        <img src="/images/rb-favicon.png" alt="Resourcebase" height="22">
                    </span>
                    <span class="logo-lg">
                        <img src="/images/rb-favicon.png" alt="Resourcebase" height="22" class="mr-2 mb-1">
                        <div style="font-size: 1.2em; display: inline-block">{{ appName() }}</div>
                    </span>
                </router-link>
            </div>

            <button
                type="button"
                class="btn btn-sm px-3 font-size-16 header-item"
                id="vertical-menu-btn"
                @click="toggleSideNav()"
            >
                <i class="fa fa-fw fa-bars"></i>
            </button>
        </div>

        <div class="d-flex">
            <div class="dropdown d-inline-block">
                <button
                    type="button"
                    class="btn header-item waves-effect"
                    id="page-header-user-dropdown"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <!--<img class="rounded-circle header-profile-user"
                    src="http://skote-v.laravel.themesbrand.com/assets/images/users/avatar-1.jpg" alt="Header Avatar">-->

                    <!--<span class="fa-stack" style="position: relative; top: -2px">
                        <i class="fas fa-circle fa-stack-1x" style="font-size: 32px; position: relative; left: -3px; top: -2px"></i>
                        <i class="fas fa-user-alt fa-stack-1x fa-inverse" style="font-size: 18px"></i>
                    </span>-->
                    <div class="avatar-xs" style="display: inline-block; width: 36px; height: 36px">
                        <span
                            class="avatar-title rounded-circle bg-soft-primary text-primary font-size-14"
                        >{{ initial(userName()) }}</span>
                    </div>

                    <span class="d-none d-xl-inline-block ml-1">{{ userName() }}</span>
                    <i class="far fa-chevron-down d-none d-xl-inline-block" style="font-size: 7px; position: relative; left: 1px; top: -2px"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right">
                    <a
                        class="dropdown-item"
                        href="#"
                        data-toggle="modal"
                        data-target="#change-password-modal"
                        @click="resetChangePasswordForm()"
                    >
                        <i class="far fa-key mr2"></i> Change password
                    </a>
                    <div class="dropdown-divider"></div>
                    <span v-if="!logging_out">
                        <a class="dropdown-item text-danger" href="#" @click.prevent="logout()">
                            <i class="far fa-power-off mr5"></i> Logout 
                        </a>
                    </span>
                    <span v-else>
                        <span style="padding-left: 24px; line-height: 30px">
                            <i class="far fa-spinner-third fa-spin mr5"></i> 
                            <span style="position: relative; top: 1px">Logout</span>
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                logging_out: false
            }
        },
        mounted () {
            //
        },
        methods: {
            logout: function () {
                this.logging_out = true;
                const url = this.$root.$data.app.api_url + '/user/logout';
                const data = {};
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    vm.$root.doLogout();
                })
                .catch(function (error) {
                    console.log(error);
                    alert('Sorry, something went wrong. Please try again.');
                })
                .finally(() =>
                    vm.logging_out = false
                );
            },
            resetChangePasswordForm: function () {
                var component = this.$root.findComponent(this.$parent, 'change-password');
                if (component) {
                    component.resetForm();
                }
            },
            appName: function () {
                return this.$root.$data.app.name ? this.$root.$data.app.name : '';
            },
            userName: function () {
                return this.$root.$data.user ? this.$root.$data.user.name : '';
            },
            toggleSideNav: function () {
                this.$parent.toggleMenu();
            },
            initial: function (name) {
                const letter = name.substr(0,1);

                return letter.toUpperCase();
            }
        }
    }
</script>
