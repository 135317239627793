<template>
    <div>
        <div class="page-content" style="min-height: 600px">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="page-title-box d-flex align-items-center justify-content-between">
                            <h4 class="mb-0 font-size-18">{{ clientName() }} - API calls</h4>

                            <div class="page-title-right">
                                <breadcrumbs :items="breadcrumbs"></breadcrumbs>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-3 col-md-4 col-6">
                        <div class="form-group date-range">
                            <label for="filter-by-date-range">Date range</label>
                            <date-range-picker
                                v-model="date_range"
                                :opens="'right'"
                                :locale-data="{ format: 'dd/mm/yyyy', firstDay: 1 }"
                                :max-date="new Date()"
                                @update="updateDateRange"
                                :autoApply="true"
                            >
                                <template v-slot:input="picker">
                                    <span v-html="showDateRange(picker)"></span>
                                </template>
                            </date-range-picker>
                            <button
                                v-if="(date_range.startDate != null)"
                                class="btn btn-link clear-date-range"
                                @click.prevent="clearDateRange()"
                            >
                                <i class="far fa-times"></i>
                            </button>
                        </div>
                    </div>
                    <div class="col text-right" style="padding-top: 40px">Total number of calls: {{ total_calls }}</div>
                </div>

                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                <div v-if="loading"><loading></loading></div>

                <div
                    v-if="(!errors && !loading && (items.length == 0) && (date_range.startDate != null))"
                    class="alert alert-warning"
                    role="alert"
                >
                    There are no calls logged in this period
                </div>

                <div v-if="(!errors && !loading && (items.length > 0))" class="row">
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="table-responsive">
                                    <table class="table table-centered table-nowrap table-hover">
                                        <thead class="thead-light">
                                            <tr>
                                                <th scope="col">Method</th>
                                                <th scope="col">Path</th>
                                                <th scope="col">Number of calls</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in items" :key="item.id">
                                                <td>{{ item.method }}</td>
                                                <td>{{ item.path }}</td>
                                                <td>{{ item.number_of_calls }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <site-footer></site-footer>
    </div>
</template>

<script>
    import DateRangePicker from 'vue2-daterange-picker';
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
    export default {
        components: { DateRangePicker },
        data () {
            return {
                breadcrumbs: [
                    {
                        id: 0,
                        text: "Clients",
                        active: true,
                        link: 'clients'
                    },
                    {
                        id: 1,
                        text: "Client API calls"
                    }
                ],
                loading: false,
                errors: false,
                client: null,
                admin_key: null,
                date_range: {
                    startDate: null,
                    endDate: null
                },
                total_calls: 0,
                items: []
            }
        },
        mounted () {
            this.loading = true;
            this.errors = false;

            const todaysDate = new Date();
            let month = ('0' + (todaysDate.getMonth() + 1)).slice(-2);
            let day = ('0' + todaysDate.getDate()).slice(-2);
            const today = todaysDate.getFullYear() + '-' + month + '-' + day;

            const currentDate = new Date();
            month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
            this.date_range.startDate = currentDate.getFullYear() + '-' + month + '-01';

            const lastDayOfMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
            day = ('0' + lastDayOfMonthDate.getDate()).slice(-2);
            const lastDayOfMonth = currentDate.getFullYear() + '-' + month + '-' + day;

            if (lastDayOfMonth < today) {
                this.date_range.endDate = lastDayOfMonth;
            } else {
                this.date_range.endDate = today;
            }
            this.getClient();
            this.$root.expandSideNav();

            var vm = this;
            this.get_api_calls_interval = setInterval(function () {
                vm.getApiCalls();
            }, 15000);
        },
        beforeDestroy() {
            clearInterval(this.get_api_calls_interval);
        },
        watch: {
            //
        },
        methods: {
            getClient: function () {
                if (!this.$route.params.client_id) {
                    this.errors = 'Missing client ID';

                    return null;
                }

                /**
                 * Get client from API
                 */
                const url = this.$root.app.api_url + '/client/' + this.$route.params.client_id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.client = response.data.client;
                    vm.getAdminKey();
                })
                .catch(function (error) {
                    vm.loading = false;
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response) {
                        if (error.response.status == 401) {
                            // missing/lost API token - close modal and logout user
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            vm.errors = error.response.data.error;
                        }
                    }
                });
            },
            clientName: function () {
                if (!this.$route.params.client_id) {
                    return '';
                }
                if (this.client) {
                    return this.client.client_name;
                }

                return this.$route.params.client_id;
            },
            getAdminKey: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/admin-key';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.admin_key = response.data;
                    vm.getApiCalls();
                })
                .catch(function (error) {
                    vm.loading = false;
                    vm.errors = 'Could not retrieve admin key';
                    if (error.response.data.error) {
                        vm.errors += '. ' + error.response.data.error;
                    }
                });
            },
            showDateRange: function(picker) {
                const icon = '<i class="fal fa-calendar-alt font-size-18 mr-3" style="position: relative; top: 2px; '
                    + 'opacity: 0.8"></i>';
                if (!picker.startDate) {
                    return icon + 'Select date range';
                }

                return icon + this.formatDate(picker.startDate) + ' - ' + this.formatDate(picker.endDate);
            },
            updateDateRange: function () {
                this.date_range = {
                    startDate: this.formatDate(this.date_range.startDate, 'Y-m-d'),
                    endDate: this.formatDate(this.date_range.endDate, 'Y-m-d')
                }
                this.dateRangeSearch();
            },
            formatDate: function (date, format = 'd/m/Y') {
                const newDate = new Date(date);
                const month = ('0' + (newDate.getMonth() + 1)).slice(-2);
                const day = ('0' + newDate.getDate()).slice(-2);
                if (format == 'd/m/Y') {
                    return day + '/' + month + '/' + newDate.getFullYear();
                }

                return newDate.getFullYear() + '-' + month + '-' + day;
            },
            clearDateRange: function () {
                this.date_range = {
                    startDate: null,
                    endDate: null
                };
                this.dateRangeSearch();
            },
            dateRangeSearch: function () {
                if (this.date_range.startDate != null) {
                    this.loading = true;
                    this.getApiCalls();

                    return null;
                }
                this.items = [];
                this.total_calls = 0;
            },
            getApiCalls: function () {
                if (!this.admin_key) {
                    return null;
                }
                if (this.date_range.startDate == null) {
                    return null;
                }

                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$route.params.client_id + '/api-calls/admin/'
                    + this.admin_key + '/dr::' + this.date_range.startDate + '-' + this.date_range.endDate;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    let items = [];
                    vm.total_calls = 0;
                    response.data.calls.forEach((item) => {
                        items.push(item);
                        vm.total_calls += parseInt(item.number_of_calls);
                    });
                    vm.items = items;
                })
                .catch(function (error) {
                    //
                })
                .finally(() => {
                    vm.loading = false;
                });
            }
        }
    }
</script>
<style scoped>
    .vue-daterange-picker {
        width: 100%;
    }
    .form-group.date-range {
        position: relative;
    }

    .clear-date-range {
        position: absolute;
        top: 25px;
        right: 4px;
        z-index: 9;
    }
</style>
