import Vue from 'vue';
import VueCookie from 'vue-cookie';
import VueTheMask from 'vue-the-mask';
import VueColor from 'vue-color';
import { BootstrapVue } from "bootstrap-vue";

Vue.use(BootstrapVue);
Vue.use(VueCookie);
Vue.use(VueTheMask);
var Chrome = VueColor.Chrome;

Vue.component('main-nav', require('./components/MainNav.vue').default);
Vue.component('loading', require('./components/Loading.vue').default);
Vue.component('site-footer', require('./components/SiteFooter.vue').default);
Vue.component('clients-list', require('./components/clients/ClientsList.vue').default);
Vue.component('client-initial-user', require('./components/clients/ClientInitialUser.vue').default);
Vue.component('client-initial-user-delete', require('./components/clients/ClientInitialUserDelete.vue').default);
Vue.component('client-form', require('./components/clients/ClientForm.vue').default);
Vue.component('client-roles', require('./components/clients/ClientRoles.vue').default);
Vue.component('client-role', require('./components/clients/ClientRole.vue').default);
Vue.component('client-additional-colours', require('./components/clients/ClientAdditionalColours.vue').default);
Vue.component('client-additional-colour', require('./components/clients/ClientAdditionalColour.vue').default);
Vue.component('edit-content', require('./components/clients/EditContent.vue').default);
Vue.component('users-list', require('./components/users/UsersList.vue').default);
Vue.component('user-form', require('./components/users/UserForm.vue').default);
Vue.component('user-delete', require('./components/users/UserDelete.vue').default);
Vue.component('change-password', require('./components/ChangePassword.vue').default);
Vue.component('forgotten-password', require('./components/ForgottenPassword.vue').default);
Vue.component('login-header', require('./components/LoginHeader.vue').default);
Vue.component('login-footer', require('./components/LoginFooter.vue').default);
Vue.component('side-nav', require('./components/SideNav.vue').default);
Vue.component('side-nav-content', require('./components/SideNavContent.vue').default);
Vue.component('breadcrumbs', require('./components/Breadcrumbs.vue').default);

Vue.component('tickbox', {
	template: `
		<div>
			<span v-if="!checked">
				<span @click="checked = true">
					<i class="far fa-square tickbox"></i>
				</span>
			</span>
			<span v-else>
				<strong @click="checked = false">
					<i class="far fa-check-square tickbox"></i>
				</strong>
			</span>
			<input
				type="checkbox"
				:id="field"
				:checked="checked"
				@change="checked = !checked"
				style="display: none"
			>
			<label :for="field">{{ label }}</label>
		</div>`,
	props: ['field', 'value', 'label'],
	data () {
		return {
			checked: false
		}
	},
	mounted () {
		this.checked = this.value;
	},
	watch: {
		checked: function (val) {
			this.$emit('input', val);
		}
	}
});

Vue.component('colorpicker', {
	components: {
		'chrome-picker': Chrome,
	},
	template: `
		<div class="input-group color-picker" ref="colorpicker">
			<input type="text" class="form-control" v-model="colorValue" @focus="showPicker()" @input="updateFromInput" />
			<div class="input-group-append color-picker-container">
				<span class="input-group-text">
					<span class="current-color" :style="'background-color: ' + colorValue" @click="togglePicker()"></span>
				</span>
				<chrome-picker :value="colors" @input="updateFromPicker" v-if="displayPicker" />
			</div>
		</div>`,
	props: ['color'],
	data () {
		return {
			colors: {
				hex: '#000000',
			},
			colorValue: '',
			displayPicker: false,
		}
	},
	mounted () {
		this.setColor(this.color || '#000000');
	},
	methods: {
		updateStyles: function () {
			$('.vc-input__label').hide();
			$('.vc-chrome-toggle-btn').hide();
			$('.vc-alpha-container').hide();
			$('.vc-input__input').addClass('form-control form-control-sm font-size-13');
			$('.vc-input__input').css('font-family', 'Poppins, sans-serif');
			$('.vc-input__input').removeClass('vc-input__input');
		},
		setColor(color) {
			this.updateColors(color);
			this.colorValue = color;
		},
		updateColors(color) {
			this.updateStyles();
			if(color.slice(0, 1) == '#') {
				this.colors = {
					hex: color
				};
			}
			else if(color.slice(0, 4) == 'rgba') {
				var rgba = color.replace(/^rgba?\(|\s+|\)$/g,'').split(','),
					hex = '#' + ((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1);
				this.colors = {
					hex: hex,
					a: rgba[3],
				}
			}
		},
		showPicker() {
			var vm = this;
			setTimeout(function () {
				vm.updateStyles();
			}, 200);
			document.addEventListener('click', this.documentClick);
			this.displayPicker = true;
		},
		hidePicker() {
			document.removeEventListener('click', this.documentClick);
			this.displayPicker = false;
			this.$emit('blur');
		},
		togglePicker() {
			this.displayPicker ? this.hidePicker() : this.showPicker();
		},
		updateFromInput() {
			this.updateColors(this.colorValue);
		},
		updateFromPicker(color) {
			this.colors = color;
			if(color.rgba.a == 1) {
				this.colorValue = color.hex;
			}
			else {
				this.colorValue = 'rgba(' + color.rgba.r + ', ' + color.rgba.g + ', ' + color.rgba.b + ', ' + color.rgba.a + ')';
			}
		},
		documentClick(e) {
			var el = this.$refs.colorpicker,
				target = e.target;
			if(el !== target && !el.contains(target)) {
				this.hidePicker()
			}
		}
	},
	watch: {
		color(val) {
			this.setColor(val);
		},
		colorValue(val) {
			if(val) {
				this.updateColors(val);
				this.$emit('input', val);
			}
		}
	},
});