<template>
    <div>
        <div class="page-content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="page-title-box d-flex align-items-center justify-content-between">
                            <h4 class="mb-0 font-size-18">Initial client users</h4>

                            <div class="page-title-right">
                                <breadcrumbs :items="breadcrumbs"></breadcrumbs>
                            </div>
                        </div>
                    </div>
                </div>

                <p>
                    The following top level admin users will be added automatically to the system when
                    a new client is created
                </p>

                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                <div v-if="loading"><loading></loading></div>

                <div v-if="(!errors && !loading)" class="row">
                    <div v-for="item in items" :key="item.id" class="col-xl-3 col-sm-6">
                        <client-initial-user :data="item"></client-initial-user>
                    </div>
                    <div class="col-xl-3 col-sm-6">
                        <client-initial-user></client-initial-user>
                    </div>
                </div>
            </div>
        </div>

        <site-footer></site-footer>

        <div
            class="modal fade"
            id="delete-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="delete-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog">
                <client-initial-user-delete></client-initial-user-delete>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                breadcrumbs: [
                    {
                        id: 0,
                        text: "Clients",
                        active: true,
                        link: 'clients'
                    },
                    {
                        id: 1,
                        text: "Initial client users"
                    }
                ],
                loading: false,
                errors: false,
                items: [],
                num_items: 0
            }
        },
        mounted () {
            this.loading = true;
            this.getUsers();

            this.$root.expandSideNav();
        },
        methods: {
            getUsers: function () {
                this.errors = false;

                /**
                 * Send request to API
                 */
                const url = this.$root.$data.app.api_url + '/clients/initial-users';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    vm.items = response.data.users;
                    vm.num_items = vm.items.length;
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.loading = false;
                });
            },
            confirmDelete: function (item) {
                var component = this.$root.findComponent(this, 'client-initial-user-delete');
                if (component) {
                    component.confirmDeletion(item);
                    $('#delete-modal').modal('show');
                }
            }
        }
    }
</script>
