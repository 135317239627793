<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="delete-modal-label">Delete initial client user</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true"><i class="fal fa-times"></i></span>
            </button>
        </div>

        <div v-if="deleted">
            <div class="modal-body">
                <div class="alert alert-success" role="alert">
                    <i class="far fa-check mr-2"></i>
                    <span v-html="deleted"></span>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" data-dismiss="modal">
                    <i class="fas fa-times mr2"></i> Close
                </button>
            </div>
        </div>

        <div v-else>
            <div class="modal-body">
                Please confirm that you want to delete user {{ item.first_name }} {{ item.last_name }} {{ item.email }}

                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert" style="margin-top: 16px"></div>
            </div>

            <div class="modal-footer">
                <span v-if="!deleting">
                    <span>
                        <button type="button" class="btn btn-danger" @click.prevent="doDelete(item.id)">
                            <i class="fas fa-trash mr2"></i> Delete
                        </button>
                    </span>
                </span>
                <span v-else>
                    <button type="button" class="btn btn-danger">
                        <i class="fal fa-spinner-third fa-spin mr2"></i>
                        <span style="opacity: 0.5">Delete</span>
                    </button>
                </span>
                <button type="button" class="btn btn-light" data-dismiss="modal" :disabled="deleting">
                    <i class="fas fa-times mr2"></i> Cancel
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
         data () {
            return {
                item: false,
                errors: false,
                deleting: false,
                deleted: false
            }
        },
        mounted () {
            //
        },
        methods: {
            confirmDeletion: function (item) {
                this.item = item;
                this.errors = false;
                this.deleting = false;
                this.deleted = false;
            },
            doDelete: function (id) {
                this.deleting = true;

                /**
                 * Send request to API
                 */
                const url = this.$root.$data.app.api_url + '/clients/initial-user/' + id;
                var vm = this;

                axios({
                    method: 'delete',
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    vm.deleted = 'Success';
                    if (response.data.message) {
                        vm.deleted = response.data.message;
                    }

                    setTimeout(function () {
                        $('#delete-modal').modal('hide');
                    }, 2000);
                    vm.$parent.getUsers();
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response) {
                        if (error.response.status == 401) {
                            // missing/lost API token - close modal and logout user
                            $('#delete-modal').modal('hide');
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            vm.errors = error.response.data.error;
                        }
                    }
                })
                .finally(() =>
                    vm.deleting = false
                );
            }
        }
    }
</script>
