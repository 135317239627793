<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <div v-if="loading"><loading></loading></div>

        <div v-else class="vertical-tabs">
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item" role="presentation">
                    <a
                        :class="tabClass(0)"
                        id="tab-new"
                        data-toggle="tab"
                        href="#content-new"
                        role="tab"
                        aria-controls="content-new"
                        :aria-selected="tabSelected(0)"
                        @click="onAddNewRoleClick()"
                    ><i class="fas fa-plus mr2"></i> Add new role</a>
                </li>

                <li v-for="role in roles" :key="role.id" class="nav-item" role="presentation">
                    <a
                        :class="tabClass(role.id)"
                        :id="tabId(role.id)"
                        data-toggle="tab"
                        :href="tabHref(role.id)"
                        role="tab"
                        :aria-controls="contentId(role.id)"
                        :aria-selected="tabSelected(role.id)"
                        @click="showTabContent(role.id)"
                    >{{ role.name }}</a>
                </li>
            </ul>

            <div class="tab-content">
                <div :class="contentClass(0)" id="content-new" role="tabpanel" aria-labelledby="tab-new">
                    <client-role :duplicate="duplicate_role"></client-role>
                </div>

                <div
                    v-for="role in roles"
                    :key="role.id"
                    :class="contentClass(role.id)"
                    :id="contentId(role.id)"
                    role="tabpanel"
                    :aria-labelledby="tabId(role.id)"
                >
                    <client-role
                        :role="role"
                        :permissions="permissions"
                        :auto_approve_role_original="auto_approve_role"
                    ></client-role>
                </div>
            </div>

            <div style="clear: both"></div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['data', 'auto_approve_role_original'],
        data () {
            return {
                loading: false,
                errors: false,
                client_id: '',
                roles: [],
                permissions: [],
                first_role: 0,
                auto_approve_role: null,
                duplicate_role: null
            }
        },
        mounted () {
            this.loading = true;
            this.client_id = this.data;
            this.getRoles();
        },
        watch: {
            auto_approve_role_original: function (val) {
                if (!val || (this.roles.length == 0)) {
                    this.auto_approve_role = null;

                    return null;
                }
                let itemIndex = -1;
                this.roles.forEach((item, index) => {
                    if (item.original_id) {
                        if (item.original_id == val) {
                            itemIndex = index;
                        }
                    } else if (item.id == val) {
                        itemIndex = index;
                    }
                });
                if (itemIndex > -1) {
                    this.auto_approve_role = this.roles[itemIndex].id;

                    return null;
                }
                this.auto_approve_role = null;
            }
        },
        methods: {
            getRoles: function () {
                this.errors = false;

                /**
                 * Send request to API
                 */
                var url = this.$root.$data.app.api_url + '/clients/roles';
                if (this.client_id) {
                    url += '/' + this.client_id;
                }
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    response.data.roles.forEach((item) => {
                        if (item.original_id) {
                            if (item.original_id == vm.auto_approve_role_original) {
                                vm.auto_approve_role = item.id;
                            }
                        }
                    });
                    vm.roles = response.data.roles;
                    vm.permissions = response.data.permissions;
                    vm.first_role = (vm.roles.length > 0) ? vm.roles[0].id : 0;
                    if ((vm.roles.length > 0) && !vm.auto_approve_role) {
                        const lastRole = vm.roles[vm.roles.length - 1];
                        const lastRoleOriginalId = (lastRole.original_id > 0) ? lastRole.original_id : lastRole.id;
                        vm.$parent.setAutoApproveRole(lastRoleOriginalId);
                    }
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#client-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.loading = false;
                });
            },
            tabClass: function (roleId) {
                return (this.first_role == roleId) ? 'nav-link active' : 'nav-link';
            },
            tabId: function (roleId) {
                return 'tab-' + roleId;
            },
            tabHref: function (roleId) {
                return '#tab-' + roleId;
            },
            contentId: function (roleId) {
                return 'content-' + roleId;
            },
            contentClass: function (roleId) {
                return (this.first_role == roleId) ? 'tab-pane active': 'tab-pane';
            },
            tabSelected: function (roleId) {
                return (this.first_role == roleId) ? 'true': 'false';
            },
            onAddNewRoleClick: function () {
                this.duplicate_role = null;
                this.showTabContent('new');
            },
            showTabContent: function (roleId) {
                $('.tab-pane').removeClass('active');
                $('#content-' + roleId).tab('show');
                if (roleId == 'new') {
                    setTimeout(function () {
                        $('#name-0').focus();
                    }, 500);
                }
            },
            updateRoleName: function (id, name) {
                var found = -1;
                this.roles.forEach((role, index) => {
                    if (role.id == id) {
                        found = index;
                    }
                });
                if (found > -1) {
                    this.roles[found].name = name;
                }
            },
            removeRole: function (id) {
                var found = -1;
                var availableRole = 0;
                this.roles.forEach((role, index) => {
                    if (role.id == id) {
                        found = index;
                    } else if (availableRole == 0) {
                        availableRole = role.id;
                    }
                });
                if (found > -1) {
                    // Change auto approve role if deleted.
                    let resetAutoApproveRole = false;
                    if (this.roles[found].id == this.auto_approve_role) {
                        resetAutoApproveRole = true;
                    }

                    if (availableRole > 0) {
                        $('#tab-' + availableRole).tab('show');
                        this.showTabContent(availableRole);
                    } else {
                        $('#tab-new').tab('show');
                        this.showTabContent('new');
                    }
                    this.roles.splice(found, 1);

                    if (resetAutoApproveRole && (this.roles.length > 0)) {
                        const lastRole = this.roles[this.roles.length - 1];
                        const lastRoleOriginalId = (lastRole.original_id > 0) ? lastRole.original_id : lastRole.id;
                        this.$parent.setAutoApproveRole(lastRoleOriginalId);
                    }
                }
            },
            addRole: function (role) {
                this.roles.push(role);
                this.duplicate_role = null;
                var vm = this;
                var newRoleId = role.id;
                setTimeout(function () {
                    $('#tab-' + newRoleId).tab('show');
                    vm.showTabContent(newRoleId);
                }, 200);
            },
            duplicateRole: function (name, description, permissions) {
                this.duplicate_role = {
                    name: name,
                    description: description,
                    permissions: permissions
                };
                var vm = this;
                setTimeout(function () {
                    $('#tab-new').tab('show');
                    vm.showTabContent('new');
                }, 200);
            }
        }
    }
</script>
