<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <div v-if="loading"><loading></loading></div>

        <div v-else>
            <div v-for="colour in colours" :key="colour.id">
                <client-additional-colour :data="colour"></client-additional-colour>
            </div>

            <span v-if="!adding_colour">
                <span>
                    <button type="button" class="btn btn-secondary" @click="addColour()">
                        <i class="fas fa-plus mr2"></i> Add new colour
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-secondary">
                    <i class="fal fa-spinner-third fa-spin mr2"></i>
                    <span style="opacity: 0.5">Add new colour</span>
                </button>
            </span>
            
        </div>
    </div>
</template>

<script>
    export default {
        props: ['data'],
        data () {
            return {
                loading: false,
                errors: false,
                client_id: '',
                colours: [],
                adding_colour: false,
                editing_colours: false
            }
        },
        mounted () {
            this.loading = true;
            this.client_id = this.data;
            this.getColours();
        },
        watch: {
            editing_colours: function (val) {
                this.$parent.block_submit = val;
            }
        },
        methods: {
            getColours: function () {
                this.errors = false;

                /**
                 * Send request to API
                 */
                var url = this.$root.$data.app.api_url + '/clients/additional-colours';
                if (this.client_id) {
                    url += '/' + this.client_id;
                }
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    vm.colours = response.data.colours;
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#client-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.loading = false;
                });
            },
            deleteColour: function (id) {
                this.errors = false;
                let itemIndex = -1;
                this.colours.forEach((item, index) => {
                    if (item.id == id) {
                        itemIndex = index;
                    }
                });
                if (itemIndex < 0) {
                    return null;
                }
                this.colours.splice(itemIndex, 1);

                /**
                 * Send request to API
                 */
                const url = this.$root.$data.app.api_url + '/clients/additional-colour/' + id;
                var vm = this;

                axios({
                    method: 'delete',
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    // do anything?
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#client-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                });
            },
            addColour: function () {
                this.errors = false;
                this.adding_colour = true;

                /**
                 * Send request to API
                 */
                const data = {
                    colour_name: 'New',
                    colour: '#000000',
                    text_colour: '#FFFFFF'
                };
                const url = this.$root.$data.app.api_url + '/clients/additional-colour';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    vm.colours.push(response.data.colour);
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#client-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                })
                .finally(() => {
                    vm.adding_colour = false;
                });
            }
        }
    }
</script>
