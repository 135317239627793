<template>
    <div>
        <div class="account-pages my-5 pt-sm-5">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-8 col-lg-6 col-xl-5">
                        <div class="card overflow-hidden">
                            <div class="bg-soft-primary">
                                <div class="text-primary p-4">
                                    <h5 class="text-primary">{{ $root.app.name }}</h5>
                                    <p>Please login</p>
                                </div>
                            </div>
                            <div class="card-body pt-0">
                                <login-header></login-header>

                                <div class="p-2">
                                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                                    <div v-if="password_expired">
                                        <div class="form-group">
                                            <button
                                                type="button"
                                                class="btn btn-primary"
                                                data-toggle="modal"
                                                data-target="#forgotten-password-modal"
                                                style="display: block; width: 100%"
                                                @click="resetForgottenPasswordForm()"
                                            >
                                                <i class="far fa-key mr2"></i> Change password
                                            </button>
                                        </div>
                                        <div class="text-center">
                                            <a href="#" @click.prevent="resetForm()">
                                                <i class="far fa-arrow-circle-left mr2"></i> Back to login
                                            </a>
                                        </div>
                                    </div>

                                    <div v-if="loading"><loading></loading></div>

                                    <div v-if="(!loading && enter_code)">
                                        <form v-on:submit.prevent="onSubmit(true)">
                                            <div v-if="resend_code">
                                                <div class="form-group">
                                                    Sometimes system-generated emails go into your junk, spam or deleted
                                                    folder. Please check there.
                                                </div>
                                                <div class="form-group mt-3">
                                                    <button
                                                        :key="'resend-code-button'"
                                                        type="button"
                                                        class="btn btn-primary btn-block"
                                                        @click="resendCode()"
                                                    >
                                                        <i class="far fa-undo-alt mr2"></i> Resend code
                                                    </button>
                                                </div>
                                                <div class="text-center">
                                                    <a
                                                        :key="'resend-code-cancel'"
                                                        href="#"
                                                        @click.prevent="toggleResendCode()"
                                                    >
                                                        <i class="far fa-times mr2"></i> Cancel
                                                    </a>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <label
                                                    for="login_code_1"
                                                    id="login_code-label"
                                                    class="mandatory-label mb-3"
                                                >
                                                    Enter the 6 digit code we have sent to your email. Sometimes these
                                                    go into spam/junk so please check there.
                                                </label>
                                                <div class="row">
                                                    <div class="col-sm-2 col-4">
                                                        <div class="form-group">
                                                            <input
                                                                v-model="code_1"
                                                                type="text"
                                                                v-mask="'######'"
                                                                id="login_code_1"
                                                                class="form-control mandatory-field"
                                                                :readonly="loading"
                                                                style="font-size: 2rem; text-align: center"
                                                                v-on:keyup="unHighlightErrors('login_code_1')"
                                                                v-on:change="unHighlightErrors('login_code_1')"
                                                            >
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-2 col-4">
                                                        <div class="form-group">
                                                            <input
                                                                v-model="code_2"
                                                                type="text"
                                                                v-mask="'######'"
                                                                id="login_code_2"
                                                                class="form-control mandatory-field"
                                                                :readonly="loading"
                                                                style="font-size: 2rem; text-align: center"
                                                                v-on:keyup="unHighlightErrors('login_code_2')"
                                                                v-on:change="unHighlightErrors('login_code_2')"
                                                            >
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-2 col-4">
                                                        <div class="form-group">
                                                            <input
                                                                v-model="code_3"
                                                                type="text"
                                                                v-mask="'######'"
                                                                id="login_code_3"
                                                                class="form-control mandatory-field"
                                                                :readonly="loading"
                                                                style="font-size: 2rem; text-align: center"
                                                                v-on:keyup="unHighlightErrors('login_code_3')"
                                                                v-on:change="unHighlightErrors('login_code_3')"
                                                            >
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-2 col-4">
                                                        <div class="form-group">
                                                            <input
                                                                v-model="code_4"
                                                                type="text"
                                                                v-mask="'######'"
                                                                id="login_code_4"
                                                                class="form-control mandatory-field"
                                                                :readonly="loading"
                                                                style="font-size: 2rem; text-align: center"
                                                                v-on:keyup="unHighlightErrors('login_code_4')"
                                                                v-on:change="unHighlightErrors('login_code_4')"
                                                            >
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-2 col-4">
                                                        <div class="form-group">
                                                            <input
                                                                v-model="code_5"
                                                                type="text"
                                                                v-mask="'######'"
                                                                id="login_code_5"
                                                                class="form-control mandatory-field"
                                                                :readonly="loading"
                                                                style="font-size: 2rem; text-align: center"
                                                                v-on:keyup="unHighlightErrors('login_code_5')"
                                                                v-on:change="unHighlightErrors('login_code_5')"
                                                            >
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-2 col-4">
                                                        <div class="form-group">
                                                            <input
                                                                v-model="code_6"
                                                                type="text"
                                                                v-mask="'######'"
                                                                id="login_code_6"
                                                                class="form-control mandatory-field"
                                                                :readonly="loading"
                                                                style="font-size: 2rem; text-align: center"
                                                                v-on:keyup="unHighlightErrors('login_code_6')"
                                                                v-on:change="unHighlightErrors('login_code_6')"
                                                            >
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group mt-3">
                                                    <button type="submit" class="btn btn-primary btn-block">
                                                        <i class="far fa-arrow-circle-right mr2"></i> Continue
                                                    </button>
                                                </div>
                                                <div class="text-center">
                                                    <a href="#" @click.prevent="toggleResendCode()">
                                                        <i class="far fa-undo-alt mr2"></i> Resend code
                                                    </a>
                                                </div>
                                            </div>
                                            <hr>
                                            <div class="text-center">
                                                <a href="#" @click.prevent="enter_code = false">
                                                    <i class="far fa-arrow-circle-left mr2"></i> Back to login
                                                </a>
                                            </div>
                                        </form>
                                    </div>

                                    <div v-if="(!loading && !password_expired && !enter_code)">
                                        <form v-on:submit.prevent="onSubmit()" autocomplete="off">
                                            <div class="form-group">
                                                <label for="email">Email</label>
                                                <input
                                                    v-model="email"
                                                    type="email"
                                                    id="email"
                                                    class="form-control"
                                                    autocomplete="off"
                                                >
                                            </div>
                                            <div class="form-group">
                                                <label for="password">Password</label>
                                                <input
                                                    v-model="password"
                                                    type="password"
                                                    id="password"
                                                    class="form-control"
                                                    autocomplete="new-password"
                                                >
                                            </div>
                                            <!--<div class="form-group">
                                                <tickbox
                                                    :field="'remember_me'"
                                                    v-model="remember_me"
                                                    :label="'Remember me'"
                                                ></tickbox>
                                            </div>-->
                                            <div class="form-group mt-4">
                                                <button type="submit" class="btn btn-primary btn-block">
                                                    <i class="fas fa-sign-in-alt mr2"></i> Login
                                                </button>
                                            </div>
                                        </form>

                                        <div class="text-center">
                                            <a
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#forgotten-password-modal"
                                                @click="resetForgottenPasswordForm()"
                                            >
                                                <i class="far fa-lock-alt mr5"></i> Forgot your password?
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <login-footer></login-footer>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="modal fade"
            id="forgotten-password-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="forgotten-password-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog">
                <forgotten-password></forgotten-password>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                loading: false,
                errors: false,
                email: '',
                password: '',
                remember_me: false,
                password_expired: false,
                enter_code: false,
                code: '',
                code_1: '',
                code_2: '',
                code_3: '',
                code_4: '',
                code_5: '',
                code_6: '',
                resend_code: false
            }
        },
        mounted () {
            //
        },
        watch: {
            enter_code: function (val) {
                if (!val) {
                    this.code = '';
                    this.code_1 = '';
                    this.code_2 = '';
                    this.code_3 = '';
                    this.code_4 = '';
                    this.code_5 = '';
                    this.code_6 = '';
                    this.resend_code = false
                }
            },
            code_1: function (val) {
                this.updateCode(val);
            },
            code_2: function (val) {
                this.updateCode(val);
            },
            code_3: function (val) {
                this.updateCode(val);
            },
            code_4: function (val) {
                this.updateCode(val);
            },
            code_5: function (val) {
                this.updateCode(val);
            },
            code_6: function (val) {
                this.updateCode(val);
            }
        },
        methods: {
            onSubmit: function (sendCode = false) {
                $('.mandatory-field').blur();
                $('.mandatory-label').removeClass('text-danger');
                this.loading = true;
                this.errors = false;
                this.password_expired = false;

                const url = this.$root.app.api_url + '/user/login';
                let data = {
                    email: this.email,
                    password: this.password,
                    app_info: this.$root.app
                };
                if (sendCode) {
                    data.code = this.code;
                }
                var vm = this;

                /**
                 * Send login request to API
                 */
                axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(response => {
                    if (response.data.user) {
                        vm.$root.user = response.data.user;
                        vm.$root.token = response.data.token;
                        
                        /**
                        * Create token cookie
                        */
                        const days = /*vm.remember_me ? '1Y' : */null;
                        vm.$root.setCookie('resourcebase_admin_api_token', response.data.token, days);

                        /**
                        * Redirect to requested page
                        */
                        if (vm.$root.requested_route) {
                            vm.$router.push({ name: vm.$root.requested_route });
                            vm.$root.requested_route = null;

                            return null;
                        }
                        vm.$router.push({ name: 'home' });

                        return null;
                    }
                    vm.enter_code = true;
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response) {
                        if (error.response.status == 401) {
                            vm.errors = 'Invalid credentials. Please try again.';
                        } else if (error.response.status == 406) {
                            vm.errors = 'Your password needs to be changed.'
                                + ' Please click on Change Password to send yourself a password reset link.';
                            vm.password_expired = true;
                        } else if (error.response.data.error) {
                            vm.errors = error.response.data.error;
                            if (error.response.status == 400) {
                                setTimeout(function () {
                                    $('#login_code-label').addClass('text-danger');
                                    for (var n = 1; n <= 6; n++) {
                                        if ($('#login_code_' + n).val() === '') {
                                            $('#login_code_' + n).addClass('field-error');
                                        }
                                    }
                                }, 200);
                            }
                        }
                    }
                })
                .finally(() =>
                    this.loading = false
                );
            },
            resetForm: function () {
                this.loading = false;
                this.errors = false;
                this.remember_me = false;
                this.password_expired = false;
                this.enter_code = false;
            },
            resetForgottenPasswordForm: function () {
                var component = this.$root.findComponent(this, 'forgotten-password');
                if (component) {
                    component.resetForm();
                }
            },
            toggleResendCode: function () {
                this.resend_code = !this.resend_code;
            },
            resendCode: function () {
                this.resend_code = false;
                this.code = '';
                this.code_1 = '';
                this.code_2 = '';
                this.code_3 = '';
                this.code_4 = '';
                this.code_5 = '';
                this.code_6 = '';
                this.onSubmit();
            },
            updateCode: function (val) {
                if (val.length > 1) {
                    this.code_1 = val.substr(0, 1);
                    this.code_2 = val.substr(1, 1);
                    this.code_3 = val.substr(2, 1);
                    this.code_4 = val.substr(3, 1);
                    this.code_5 = val.substr(4, 1);
                    this.code_6 = val.substr(5, 1);
                }
                this.code = this.code_1 + this.code_2 + this.code_3 + this.code_4 + this.code_5 + this.code_6;

                var vm = this;
                setTimeout(function () {
                    if (vm.code.length == 6) {
                        $('.mandatory-field').blur();
                        $('.mandatory-label').removeClass('text-danger');
                    } else {
                        for (let digit = 6; digit >= 1; digit--) {
                            if ($('#login_code_' + digit).val() == '') {
                                $('#login_code_' + digit).focus();
                            }
                        }
                    }
                }, 20);
            },
            unHighlightErrors: function (field) {
                //$('.mandatory-label').removeClass('text-danger');
                $('#' + field).removeClass('field-error');
            }
        }
    }
</script>
