<template>
    <div id="sidebar-menu">
        <ul id="side-menu" class="metismenu list-unstyled">
            <li class="menu-title">Menu</li>
            <li>
                <a href="#" class="has-arrow" @click.prevent="doNothing()">
                    <i class="far fa-clouds menu-item-icon"></i>
                    <span>Clients</span>
                </a>
                <ul class="sub-menu" aria-expanded="false">
                    <li>
                        <router-link
                            class="side-nav-link-ref"
                            :to="{ name: 'clients' }"
                        >
                            Client list
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            class="side-nav-link-ref"
                            :to="{ name: 'client-initial-users' }"
                        >
                            Initial client users
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            class="side-nav-link-ref"
                            :to="{ name: 'client-add' }"
                        >
                            + Add client
                        </router-link>
                    </li>
                </ul>
            </li>
            <li>
                <a href="#" class="has-arrow" @click.prevent="doNothing()">
                    <i class="far fa-user-friends menu-item-icon"></i>
                    <span>Admin users</span>
                </a>
                <ul class="sub-menu" aria-expanded="false">
                    <li>
                        <router-link
                            class="side-nav-link-ref"
                            :to="{ name: 'users' }"
                        >
                            User list
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            class="side-nav-link-ref"
                            :to="{ name: 'user-add' }"
                        >
                            + Add user
                        </router-link>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
    import MetisMenu from "metismenujs/dist/metismenujs";

    export default {
        mounted () {
            var menuRef = new MetisMenu("#side-menu");
        },
        methods: {
            doNothing: function () {
                return false;
            }
        }
    }
</script>
