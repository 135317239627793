<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="user-modal-label">{{ form_title }}</h5>
            <router-link tag="button" class="close" data-dismiss="modal" aria-label="Close" :to="{ name: 'users' }">
                <span aria-hidden="true"><i class="fal fa-times"></i></span>
            </router-link>
        </div>

        <div v-if="loading">
            <div class="modal-body">
                <loading></loading>
            </div>
        </div>

        <div v-if="updated">
            <div class="modal-body">
                <div class="alert alert-success" role="alert">
                    <i class="far fa-check mr-2"></i>
                    <span v-html="updated"></span>
                </div>
            </div>
            <div class="modal-footer">
                <router-link tag="button" class="btn btn-light" data-dismiss="modal" :to="{ name: 'users' }">
                    <i class="fas fa-times mr2"></i> Close
                </router-link>
            </div>
        </div>

        <div v-if="(!loading && !updated)">
            <form v-on:submit.prevent="onSubmit()">
                <div class="modal-body">
                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                    <div v-if="!loading_errors">
                        <div class="form-group">
                            <label for="name" id="name-label" class="mandatory-label">Name</label>
                            <input
                                v-model="name"
                                type="text"
                                id="name"
                                class="form-control mandatory-field"
                                :readonly="updating"
                            >
                        </div>
                        <div class="form-group">
                            <label for="email" id="email-label" class="mandatory-label">Email</label>
                            <input
                                v-model="email"
                                type="email"
                                id="email"
                                class="form-control mandatory-field"
                                aria-describedby="email-help"
                                :readonly="updating"
                            >
                            <small id="email-help" class="form-text text-muted">
                                This will be used for login
                            </small>
                        </div>
                        <div v-if="!existing_user" class="form-group">
                            A password will be generated automatically and sent to the user in email
                        </div>
                    </div>
                </div>
                <div v-if="!loading_errors" class="modal-footer">
                    <span v-if="!updating">
                        <span>
                            <button type="submit" class="btn btn-primary">
                                <i :class="button_icon"></i> {{ button_text }}
                            </button>
                        </span>
                    </span>
                    <span v-else>
                        <button type="button" class="btn btn-primary">
                            <i class="fal fa-spinner-third fa-spin mr2"></i>
                            <span style="opacity: 0.5">{{ button_text }}</span>
                        </button>
                    </span>
                    <router-link
                        tag="button"
                        class="btn btn-light"
                        data-dismiss="modal"
                        :disabled="updating"
                        :to="{ name: 'users' }"
                    >
                        <i class="fas fa-times mr2"></i> Cancel
                    </router-link>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                loading: false,
                loading_errors: false,
                errors: false,
                form_title: 'Add admin user',
                button_icon: 'fas fa-plus mr2',
                button_text: 'Add',
                updating: false,
                updated: false,
                existing_user: false,
                id: 0,
                name: '',
                email: ''
            }
        },
        mounted () {
            //
        },
        methods: {
            editUser: function (id = 0) {
                this.id = id;
                this.loading_errors = false;
                this.errors = false;
                this.updated = false;
                this.$parent.$data.errors = false;
                this.loading = true;
                var vm = this;

                if (id == 0) {
                    // new user - empty fields
                    this.existing_user = false;
                    this.form_title = 'Add admin user';
                    this.button_icon = 'fas fa-plus mr2';
                    this.button_text = 'Add';
                    this.name = '';
                    this.email = '';
                    setTimeout(function () {
                        vm.loading = false;
                    }, 500);
                } else {
                    // existing user - get details and populate fields
                    this.existing_user = true;
                    this.form_title = 'Edit admin user';
                    this.button_icon = 'far fa-save mr2';
                    this.button_text = 'Save';

                    /**
                     * Get user from API
                     */
                    const url = this.$root.$data.app.api_url + '/user/' + this.id;

                    axios({
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                    })
                    .then(response => {
                        vm.name = response.data.user.name;
                        vm.email = response.data.user.email;
                    })
                    .catch(function (error) {
                        vm.loading_errors = true;
                        vm.errors = 'Something went wrong, please try again';
                        if (error.response) {
                            if (error.response.status == 401) {
                                // missing/lost API token - close modal and logout user
                                $('#user-modal').modal('hide');
                                vm.$root.doLogout();
                            }
                            if (error.response.data.error) {
                                vm.errors = error.response.data.error;
                            }
                        }
                    })
                    .finally(() =>
                        vm.loading = false
                    );
                }
            },
            onSubmit: function () {
                this.updating = true;
                this.errors = false;

                /**
                 * Send request to API
                 */
                var path = '/user/' + this.id;
                var data = {
                    name: this.name,
                    email: this.email
                };
                var method = 'put';

                if (!this.existing_user) {
                    path = '/user';
                    data.app_info = this.$root.$data.app;
                    method = 'post';
                }

                const url = this.$root.$data.app.api_url + path;
                var vm = this;

                axios({
                    method: method,
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    vm.updated = 'Success';
                    if (response.data.message) {
                        vm.updated = response.data.message;
                    }

                    if (vm.existing_user && (vm.$root.$data.user.id == vm.id)) {
                        vm.$root.$data.user.name = vm.name;
                        vm.$root.$data.user.email = vm.email;
                    }

                    setTimeout(function () {
                        $('#user-modal').modal('hide');
                        if (!vm.existing_user) {
                            vm.$router.push({ name: 'users' });
                        }
                    }, 2000);

                    if (vm.existing_user) {
                        vm.$parent.getUsers();
                    }
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response) {
                        if (error.response.status == 401) {
                            // missing/lost API token - close modal and logout user
                            $('#user-modal').modal('hide');
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error;
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = '';
                                $('.mandatory-label').removeClass('text-danger');
                                $('.mandatory-field').removeClass('field-error');
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    vm.errors += value.join('<br>') + '<br>';
                                    $('#' + key + '-label').addClass('text-danger');
                                    $('#' + key).addClass('field-error');
                                }
                            }
                        }
                    }
                })
                .finally(() =>
                    vm.updating = false
                );
            }
        }
    }
</script>
