<template>
    <div>
        <header v-if="!$root.isPublicPage($route.name)" id="page-topbar">
            <main-nav></main-nav>
        </header>

        <side-nav v-if="!$root.isPublicPage($route.name)" :is-condensed="isMenuCondensed"></side-nav>

        <div :class="mainClass()">
            <router-view></router-view>
        </div>

        <div
            v-if="!$root.isPublicPage($route.name)"
            class="modal fade"
            id="change-password-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="change-password-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog">
                <change-password></change-password>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                isMenuCondensed: false
            }
        },
        created () {
            this.$root.$data.app = this.$attrs.app_info;

            /**
             * Check user
             */
            if (!this.$root.isLoggedIn()) {
                if (this.$cookie.get('resourcebase_admin_api_token')) {
                    this.$root.$data.token = this.$cookie.get('resourcebase_admin_api_token');
                    this.getUser();
                }
            }
        },
        mounted () {
            //
        },
        methods: {
            getUser: async function () {
                try {
                    const url = this.$root.$data.app.api_url + '/user';
                    var vm = this;

                    const response = await axios({
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                    });

                    vm.$root.$data.user = response.data.user;
                } catch (error) {
                    this.$root.doLogout();
                }
            },
            mainClass: function () {
                return this.$root.isPublicPage(this.$route.name) ? '' : 'main-content';
            },
            toggleMenu() {
                document.body.classList.toggle("sidebar-enable");
                if (window.screen.width >= 992) {
                    document.body.classList.toggle("vertical-collpsed");
                } else {
                    document.body.classList.remove("vertical-collpsed");
                }
                this.isMenuCondensed = !this.isMenuCondensed;
            }
        }
    }
</script>